interface chartControl {
    whereClause: String,
    definitionParameters: {
        OrgUnit_ID: number | null,
        WBS_ID: number | null,
        FromPreviousCutOff: boolean,
        StartDate: string | null,
        FinishDate: string | null,
        AllPeriods: boolean
    },
    createOrRefresh: Function,
}

export const chartControl: chartControl = {
    whereClause: '',
    definitionParameters: {
        OrgUnit_ID: null,
        WBS_ID: null,
        FromPreviousCutOff: false,
        StartDate: null,
        FinishDate: null,
        AllPeriods: false
    },
    createOrRefresh: () => {}, //must override from chart component
}